import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/dashboardStyles.module.scss';
import { BookmarkIcon } from '../../../assets/icons';

const SavedDoctors = () => {
    const { t } = useTranslation();

    return (
        <div className='pt-3'>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3">
                <div className="d-flex flex-column">
                    <h5 className='defaultHeader'>Dr. Sofia Malia</h5>
                    <span className={styles.desc}>Mjeke e pergjithshme</span>
                </div>
                <span className='pointer'>
                    <BookmarkIcon />
                </span>
            </div>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 pt-4">
                <div className="d-flex flex-column">
                    <h5 className='defaultHeader'>Dr. Majlinda Kacorri</h5>
                    <span className={styles.desc}>Mjeke specializante</span>
                </div>
                <span className='pointer'>
                    <BookmarkIcon />
                </span>
            </div>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 pt-4">
                <div className="d-flex flex-column">
                    <h5 className='defaultHeader'>Dr. Zenun Kasemi</h5>
                    <span className={styles.desc}>Doktor zemre</span>
                </div>
                <span className='pointer'>
                    <BookmarkIcon />
                </span>
            </div>
        </div>
    )
}

export default SavedDoctors;