import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="#303030"
        d="M6.648 8.638a1.614 1.614 0 100-3.229 1.614 1.614 0 000 3.23z"
      ></path>
      <path
        fill="#303030"
        d="M11.214 2.135h.001a6.458 6.458 0 010 9.132l-3.489 3.489a1.524 1.524 0 01-2.154 0l-3.47-3.471-.019-.018a6.458 6.458 0 019.131-9.132zm-8.446.684a5.49 5.49 0 000 7.763l.014.015.002.001 3.472 3.473a.555.555 0 00.784 0l3.49-3.49A5.489 5.489 0 002.767 2.82z"
      ></path>
    </svg>
  );
}

export default Icon;