import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../styles/doctorScreen.module.scss';
import { BookIcon, EuroIcon, PhoneIcon, PinIcon, WarningIcon } from '../../../assets/icons';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Tab, Tabs } from '@mui/material';
import DoctorImage from '../../../assets/images/DoctorCardImage.png';
import Star from '../../../assets/images/Star.png';
import PrimaryCare from '../../../assets/images/specialities/primaryCare.png';
import Experience from '../../../assets/images/Experience.png';
import Services from '../../../assets/images/Services.png';
import Education from '../../../assets/images/Education.png';
import Reviews from '../../../assets/images/Reviews.png';
import Title from '../../../components/shared /Title';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import ReviewBar from '../../../components/shared /ReviewBar';
import Locked from '../../../assets/images/locked.png';
import ReviewItem from '../../../components/client/ReviewItem';

const DoctorScreen = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [loggedIn, setLoggedIn] = useState<boolean>(true);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [showMoreReviews, setShowMoreReviews] = useState<boolean>(false);
    const [experiencesToShow, setExperiencesToShow] = useState<number>(2);
    const [reviewsToShow, setReviewsToShow] = useState<number>(5);
    const [activeTab, setActiveTab] = useState<string>('all');
    const [experiences, setExperiences] = useState<Array<any>>(
        [
            { name: 'Nutrition', text: 'Lorem Ipsum is simply dummy text of the print' },
            { name: 'Obesity', text: 'Lorem Ipsum is simply dummy text of the print' },
            { name: 'Other', text: 'Lorem Ipsum is simply dummy text of the print' }
        ]
    );
    const [services, setServices] = useState<Array<any>>([
        { title: 'Spirometry', description: 'Lorem Ipsum is simply dummy text of the print' },
        { title: 'Nutrition Assessment', description: 'Lorem Ipsum is simply dummy text of the print' },
        { title: 'Rhinoplasty', description: 'Lorem Ipsum is simply dummy text of the print' }
    ])
    const [education, setEducation] = useState<any>({
        specialities: ['Family Physician', 'Primary Care Doctor'],
        education: [
            { branch: 'Medical School', university: 'University Nene Tereza', place: 'Tirane Albania' },
            { branch: 'Doctor of Medicine', university: 'University of Rome', place: 'Roma Italy' }
        ],
        workExp: [
            { position: 'Mjek Urgjence', place: 'Spitali Amerikan 3', dates: '12/12/2022 - 23/08/2023' },
            { position: 'Mjek Urolog', place: 'Spitali Universitar Nene Tereza', dates: '12/12/2022 - 23/08/2023' },
        ],
        language: ["English", "Albanian"],
        gender: "Female"
    })
    const [reviews, setReviews] = useState<Array<any>>([
        { stars: 4, comment: 'Phenomenal doctor', date: 'October 28, 2023', author: 'Arger P.', isVerified: true },
        { stars: 5, comment: 'Exeptional doctor', date: 'October 12, 2023', author: 'Arger P.', isVerified: true },
        { stars: 2, comment: 'Worst doctor', date: 'October 11, 2023', author: '', isVerified: true },
        { stars: 2, comment: 'Worst doctor', date: 'October 11, 2023', author: '', isVerified: true },
        { stars: 2, comment: 'Worst doctor', date: 'October 11, 2023', author: '', isVerified: true },
        { stars: 2, comment: 'Worst doctor', date: 'October 11, 2023', author: '', isVerified: true },
        { stars: 2, comment: 'Worst doctor', date: 'October 11, 2023', author: '', isVerified: true }
    ])
    const showAllExperience = () => {
        setExperiencesToShow(experiences.length);
    }
    const showAllReviews = () => {
        setReviewsToShow(reviews.length);
    }
    const changeActiveTab = (event: any, value: string) => {
        if (value) {
            setActiveTab(value);
        }
    }
    return (
        <>
            <div className={`${styles.wrapper} container-fluid`} >
                <div className="container">
                    <div className="row" id='about'>
                        <div className="col-md-8 col-lg-9 col-sm-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <img src={DoctorImage} className={styles.doctorImg} alt="" />
                                </div>
                                <div className="col-sm-12 col-md-8 d-flex flex-column mt-3 mt-md-0">
                                    <h3 className={`${styles.name} m-0`}>Dr. Lorena Dali</h3>
                                    <p className={`${styles.proffesion} mb-1`}>Dermatologe</p>
                                    <div className="d-flex flex-row align-items-center ">
                                        <img src={Star} className='me-1' height={17} alt="" />
                                        <span className={styles.rating}>4.3</span>
                                        <span className={styles.total}>(23 reviews)</span>
                                    </div>
                                    <div className="flex align-items-center">
                                        <PhoneIcon />
                                        <span className={styles.phone}>View phone number</span>
                                    </div>
                                    <div className="flex align-items-center">
                                        <EuroIcon />
                                        <span className={`${styles.phone} ${styles.gray}`}>View phone number</span>
                                        <span className={styles.phone}>View phone number</span>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Chip label="Chip Outlined" style={{ marginRight: 5, marginTop: 5, borderColor: 'var(--blue2)', color: 'var(--blue2)' }} variant="outlined" />
                                        <Chip label="Chip " style={{ marginRight: 5, marginTop: 5, borderColor: 'var(--blue2)', color: 'var(--blue2)' }} variant="outlined" />
                                        <Chip label="Chip Outlined" style={{ marginRight: 5, marginTop: 5, borderColor: 'var(--blue2)', color: 'var(--blue2)' }} variant="outlined" />
                                    </div>
                                    <div className="flex align-items-center pt-1">
                                        <PinIcon />
                                        <span className={`${styles.phone} ${styles.gray}`}>Spitali Amerikan 3 <span className='fw-semibold'>Tirane Albania</span></span>
                                    </div>
                                    <div className="flex align-items-center pt-1">
                                        <PinIcon />
                                        <span className={`${styles.phone} ${styles.gray}`}>Qendra Spitalore Universitare nene Tereza, <span className='fw-semibold'>Tirane Albania</span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr />
                                <p className={`${styles.description} ${showMore && styles.showAllDesc}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderi. Ut enim ad minim veniam, quis nostrud. eprehenderi. Ut enim ad minim veniam, quis nostrud, onsectetur adipiscing elit. </p>
                                {!showMore && <p onClick={() => setShowMore(true)} className={styles.showMore}>{t('more')}</p>}
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-12">
                            <div className="border border-1 rounded" >
                                <div style={{ backgroundColor: '#FDFDFD' }} className="p-3 border-bottom rounded-top">
                                    <h5 className={styles.docName}>Dr. Sofia Dali</h5>
                                </div>
                                <div style={{ backgroundColor: 'var(--white)' }} className='px-4 py-2 rounded-bottom'>
                                    <div className='flex align-items-center border-bottom pb-3 mt-2 pointer'>
                                        <a href="#about" className="text-decoration-none">
                                            <img src={PrimaryCare} width={15} height={15} alt="" />
                                            <span className={`ps-2 ${styles.docSidebarMenuItemText}`}>About The Doctor</span>
                                        </a>
                                    </div>
                                    <div className='flex align-items-center border-bottom pb-3 mt-2 pointer'>
                                        <a href="#experience" className="text-decoration-none">
                                            <img src={Experience} width={15} height={15} alt="" />
                                            <span className={`ps-2 ${styles.docSidebarMenuItemText}`}>Experience</span>
                                        </a>
                                    </div>
                                    <div className='flex align-items-center border-bottom pb-3 mt-2 pointer'>
                                        <a href="#services" className="text-decoration-none">
                                            <img src={Services} width={15} height={15} alt="" />
                                            <span className={`ps-2 ${styles.docSidebarMenuItemText}`}>Services</span>
                                        </a>
                                    </div>
                                    <div className='flex align-items-center border-bottom pb-3 mt-2 pointer'>
                                        <a href="#education" className="text-decoration-none">
                                            <img src={Education} width={15} height={15} alt="" />
                                            <span className={`ps-2 ${styles.docSidebarMenuItemText}`}>Education And Background</span>
                                        </a>
                                    </div>
                                    <div className='flex align-items-center pb-3 mt-2 pointer'>
                                        <a href="#reviews" className="text-decoration-none">
                                            <img src={Reviews} width={15} height={15} alt="" />
                                            <span className={`ps-2 ${styles.docSidebarMenuItemText}`}>Reviews</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div className="d-flex justify-content-center">
                                <button className='defaultButton mt-3' onClick={() => { }}>
                                    <BookIcon />
                                    <span className='ps-1'>Book Appointment</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-2">
                <div className="row">
                    <div className="col-sm-12 col-md-8">
                        {/* Experiences */}
                        <div className="row mb-3" id='experience' >
                            <div className="pt-2">
                                <Title text={t('experience')} />
                            </div>
                            {
                                experiences.slice(0, experiencesToShow).map((exp, index) => (
                                    <div key={index} className='pb-3 px-4'>
                                        <Chip label={exp.name} style={{ marginRight: 5, marginTop: 5, borderColor: 'var(--blue2)', color: 'var(--blue2)' }} variant="outlined" />
                                        <p className={`${styles.description} ${styles.showAllDesc} pt-3 px-1`}>{exp.text}</p>
                                    </div>
                                ))
                            }
                            {
                                experiences.length !== experiencesToShow &&
                                <span onClick={() => showAllExperience()} className='text-decoration-underline text-center pointer'>{t('more')}</span>
                            }
                        </div>
                        {/* Services */}
                        <div className="row" id='services' >
                            <div className="pt-2">
                                <Title text={t('services')} />
                            </div>
                            <div className="px-4 pb-5">
                                <h5 className={styles.sWarningMessage}>
                                    {t('services_warning')}
                                </h5>
                                <p className={`${styles.sWarningMessage} m-0 pb-4`}>
                                    <a className='me-1' onClick={() => { }} href="#">{t('sign_in')}</a>
                                    {t('or')}
                                    <a className='ms-1 me-1' onClick={() => { }} href="#">{t('sign_up')}</a>
                                    {t('to_view_prices')}
                                </p>
                                {services.map((service, i) => (
                                    <Accordion key={i}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            {service.title}
                                        </AccordionSummary>
                                        <AccordionDetails> {service.description} </AccordionDetails>
                                    </Accordion>
                                ))
                                }
                            </div>
                        </div>
                        {/* Education and background */}
                        <div className="row" id='education' >
                            <div className="pt-2">
                                <Title text={t('education')} />
                            </div>
                            <div className='px-4 mb-5'>
                                <ul className='pt-md-3 pt-xs-2'>
                                    <li className={`mb-lg-4 ${styles.listItem}`}>
                                        <div className='d-flex flex-column'>
                                            <h5 className={styles.listHeading}>{t('specialities')}</h5>
                                            {
                                                education.specialities.map((speciality: any, i: number) => (
                                                    <p key={i} className={styles.listDesc}>{speciality}</p>
                                                ))
                                            }
                                        </div>
                                    </li>
                                    <li className={`mb-lg-4 ${styles.listItem}`}>
                                        <div className='d-flex flex-column'>
                                            <h5 className={styles.listHeading}>{t('education_training')}</h5>
                                            {
                                                education.education.map((education: any, i: number) => (
                                                    <p key={i} className={styles.listDesc}>{education.branch} - {education.university}, {education.place}</p>
                                                ))
                                            }
                                        </div>
                                    </li>
                                    <li className={`mb-lg-4 ${styles.listItem}`}>
                                        <div className='d-flex flex-column'>
                                            <h5 className={styles.listHeading}>{t('work_exp')}</h5>
                                            {
                                                education.workExp.map((exp: any, i: number) => (
                                                    <p key={i} className={styles.listDesc}>{exp.position} - {exp.place}, {exp.dates}</p>
                                                ))
                                            }
                                        </div>
                                    </li>
                                    <li className={`mb-lg-4 ${styles.listItem}`}>
                                        <div className='d-flex flex-column'>
                                            <h5 className={styles.listHeading}>{t('language_spoken')}</h5>
                                            {
                                                education.language.map((lang: any, i: number) => (
                                                    <p key={i} className={styles.listDesc}>{lang}</p>
                                                ))
                                            }
                                        </div>
                                    </li>
                                    <li className={`mb-lg-4 ${styles.listItem}`}>
                                        <div className='d-flex flex-column'>
                                            <h5 className={styles.listHeading}>{t('gender')}</h5>
                                            <p className={styles.listDesc}>{education.gender}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Reviews */}
                        <div className="row mb-5" id='reviews'>
                            <div className="pt-2">
                                <Title text={t('patient_reviews')} />
                            </div>
                            <div className={`${styles.warningCard} mt-3 mb-4`}>
                                <div className="d-flex align-items-center">
                                    <WarningIcon />
                                    <p className={`m-0 ps-3 ${styles.warningText}`}>
                                        {t('reviews_warning_message')}
                                    </p>
                                </div>
                            </div>
                            <div className="px-4">
                                <div className="row mb-5">
                                    <div className="col-sm-12 col-md-6">
                                        <ReviewBar title="Name" number={4.5} />
                                        <ReviewBar title="Name" number={3} />
                                        <ReviewBar title="Name" number={2} />
                                        <ReviewBar title="Name" number={4.5} />
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="d-flex align-items-center justify-content-center h-100">
                                            <div className="d-flex flex-column">
                                                <span className={styles.totalRating}>4.1</span>
                                                <span className={styles.ratingText}>{t('overal_score')}</span>
                                                <div className={`${styles.redDivider} rounded-pill my-1`}></div>
                                                <span className={styles.totalReviews}>{t('from')} 22 {t('reviews')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !loggedIn ?
                                        <>
                                            <hr style={{ borderColor: 'var(--gray-primary)' }} />
                                            <div className="d-flex flex-column align-items-center mt-5">
                                                <img src={Locked} className='pb-3' alt="" />
                                                <p className={`${styles.sWarningMessage} m-0 pb-4`}>
                                                    <a className='me-1' onClick={() => { }} href="#">{t('sign_in')}</a>
                                                    {t('or')}
                                                    <a className='ms-1 me-1' onClick={() => { }} href="#">{t('sign_up')}</a>
                                                    {t('to_view_prices')}
                                                </p>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs

                                                    sx={{
                                                        ".MuiButtonBase-root.Mui-selected": {
                                                            color: '#303030',
                                                        },
                                                        ".MuiTabs-flexContainer": {
                                                            overflowX: 'auto'
                                                        }
                                                    }}
                                                    TabIndicatorProps={{
                                                        style: {
                                                            backgroundColor: "#0C0C0C",
                                                        }
                                                    }} value={activeTab} onChange={changeActiveTab} aria-label="basic tabs example">
                                                    <Tab label={`${t('all')} (22)`} sx={{ color: 'var(--text-secondary)' }} value={'all'} />
                                                    <Tab label={`${t('positive')} (0)`} sx={{ color: 'var(--text-secondary)' }} value={'positive'} />
                                                    <Tab label={`${t('neutral')} (2)`} sx={{ color: 'var(--text-secondary)' }} value={'neutral'} />
                                                    <Tab label={`${t('negative')} (12)`} sx={{ color: 'var(--text-secondary)' }} value={'negative'} />
                                                </Tabs>
                                            </Box>
                                            <div className="pt-4">
                                                {
                                                    reviews.slice(0, reviewsToShow).map((review, i) => (
                                                        <div key={i}>
                                                            <ReviewItem data={review} />
                                                        </div>
                                                    ))
                                                }
                                                <div className="w-100 d-flex justify-content-center mt-5">
                                                    {
                                                        reviews.length !== reviewsToShow &&
                                                        <span onClick={() => showAllReviews()} className='text-decoration-underline text-center pointer'>{t('more')}</span>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4"></div>
                </div>

            </div>
        </>
    )
}

export default DoctorScreen;