import React, { FC } from 'react';
import styles from './styles/reviewItem.module.scss';
import Star from '../../assets/images/Star.png';
import StarGray from '../../assets/images/StarGray.png';
import { useTranslation } from 'react-i18next';

interface ReviewType {
    stars: number,
    comment: string,
    date: string,
    author: string,
    isVerified: boolean
}
interface Type {
    data: ReviewType
}

const ReviewItem: FC<Type> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className='mt-4'>
            <div className="d-flex mb-3">
                {
                    [...Array(data.stars)].map(star => (
                        <img key={star} width={15} height={15} src={Star} className="me-1" alt="" />
                    ))
                }
                {5 - data.stars > 0 &&
                    [...Array(5 - data.stars)]?.map(star => (
                        <img key={star} width={15} height={15} src={StarGray} className="me-1" alt="" />
                    ))
                }
            </div>
            <h5 className={styles.comment}>{data.comment}</h5>
            <p className={styles.infoText}>
                <span>{data.date} · </span>
                <span>{data.author ? data.author : `${t('initials_hidden')}`}</span>
                {
                    data.isVerified &&
                    <span> · {`${t('verified')}`}</span>
                }

            </p>
            <hr style={{ borderColor: 'var(--gray-primary)' }} />
        </div>
    )
}

export default ReviewItem;