import React, { FC } from 'react';

interface Type {
    title: string,
    number: number
}

const ReviewBar: FC<Type> = ({ title, number }) => {
    const calculateReview = () => {
        return (number / 5) * 100;
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                <span className='defaultText'>{title}</span>
                <span className='defaultText'>{number}</span>
            </div>
            <div className='w-100 rounded-pill position-relative' style={{ backgroundColor: '#F8414140', height: 10 }}>
                <div className='rounded-pill position-absolute top-0 start-0' style={{ height: 10, width: `${calculateReview()}%`, backgroundColor: '#F84141' }}></div>
            </div>
        </>
    )
}

export default ReviewBar;