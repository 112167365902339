import React from 'react';
import styles from './styles/doctors.module.scss';
import Carousel from 'react-multi-carousel';
import Doctor from './Doctor';

const DoctorsSlider = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className='container mt-5 mb-4'>
            <h4 className={styles.heading}>Featured Doctors</h4>
            <Carousel className='mt-5' responsive={responsive} renderArrowsWhenDisabled autoPlay rewind rewindWithAnimation >
                <Doctor />
                <Doctor />
                <Doctor />
                <Doctor />
                <Doctor />
                <Doctor />
            </Carousel>
        </div>
    )
}

export default DoctorsSlider;