import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="#303030"
        d="M11.096 8.404a6.475 6.475 0 00-2.47-1.55A3.756 3.756 0 006.5 0a3.756 3.756 0 00-2.126 6.854 6.475 6.475 0 00-2.47 1.55A6.458 6.458 0 000 13h1.016A5.49 5.49 0 016.5 7.516 5.49 5.49 0 0111.984 13H13a6.457 6.457 0 00-1.904-4.596zM6.5 6.5a2.745 2.745 0 01-2.742-2.742A2.745 2.745 0 016.5 1.016a2.745 2.745 0 012.742 2.742A2.745 2.745 0 016.5 6.5z"
      ></path>
    </svg>
  );
}

export default Icon;
