import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g fill="#000" clipPath="url(#clip0_771_849)">
        <path d="M18.125 1.25h-1.563V.937a.939.939 0 00-1.875 0v.313H5.313V.937a.939.939 0 00-1.875 0v.313H1.875c-.69 0-1.25.56-1.25 1.25v13.125c0 .69.56 1.25 1.25 1.25H6.25a.312.312 0 100-.625H1.875a.626.626 0 01-.625-.625V5h17.5v10.625c0 .345-.28.625-.625.625h-3.438a.312.312 0 100 .625h3.438c.69 0 1.25-.56 1.25-1.25V2.5c0-.69-.56-1.25-1.25-1.25zM15.312.937a.313.313 0 01.626 0V2.5a.313.313 0 01-.626 0V.937zm-11.25 0a.313.313 0 01.625 0V2.5a.313.313 0 01-.625 0V.937z"></path>
        <path d="M15.313 13.125a1.877 1.877 0 00-1.876-1.875 1.877 1.877 0 00-1.874 1.875c0 .928.676 1.7 1.562 1.849v1.745a2.659 2.659 0 01-2.656 2.656 2.659 2.659 0 01-2.656-2.656v-1.737a2.816 2.816 0 002.5-2.794V9.374c0-.69-.561-1.25-1.25-1.25a.313.313 0 000 .625c.344 0 .624.28.624.625v2.813A2.19 2.19 0 017.5 14.374a2.19 2.19 0 01-2.188-2.188V9.376c0-.345.28-.625.625-.625a.313.313 0 000-.625c-.689 0-1.25.56-1.25 1.25v2.813a2.816 2.816 0 002.5 2.794v1.737A3.285 3.285 0 0010.47 20a3.285 3.285 0 003.281-3.281v-1.745a1.878 1.878 0 001.563-1.849zm-1.563.54a.625.625 0 11-.625-1.082.625.625 0 01.625 1.083z"></path>
      </g>
      <defs>
        <clipPath id="clip0_771_849">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;