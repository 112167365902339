import React, { FC } from 'react';
import styles from './styles/speciality.module.scss';
import PrimaryCare from '../../assets/images/specialities/primaryCare.png';
import Dentist from '../../assets/images/specialities/dentist.png';
import Dernatologist from '../../assets/images/specialities/dermatologist.png';
import EyeDoctor from '../../assets/images/specialities/eyeDoctor.png';
import Psychiatrist from '../../assets/images/specialities/psychiatrist.png';
import Gynecologist from '../../assets/images/specialities/gynecologist.png';

interface Type {
    text: string
}

const Speciality: FC<Type> = ({text}) => {
    const renderImage = () => {
        switch(text) {
            case 'Primary Care': 
                return <img className={styles.image} src={PrimaryCare} alt="" />
            case 'Dentist':
                return <img className={styles.image} src={Dentist} alt="" />
            case 'Gynecologist':
                return <img className={styles.image} src={Gynecologist} alt="" />
            case 'Dermatologist':
                return <img className={styles.image} src={Dernatologist} alt="" />
            case 'Psychiatrist':
                return <img className={styles.image} src={Psychiatrist} alt="" />
            case 'Eye Doctor': 
                return <img className={styles.image} src={EyeDoctor} alt="" />
        }
    }
    return (
        <div className={`${styles.wrapper} d-flex align-items-center justify-content-center`}>
            <div className="d-flex flex-column align-items-center">
                <div className={`${styles.imageWrapper} d-flex align-items-center justify-content-center`}>
                    {
                        renderImage()
                    }
                </div>
                <span className={styles.text}>{text}</span>
            </div>
        </div>
    )
}

export default Speciality;