import { combineReducers, configureStore } from '@reduxjs/toolkit';
import langReducer from './slices/langSlice';
import loadingReducer from './slices/loadingSpinnerSlice';
import userReducer from './slices/userSlice';
import { authApi } from './api/authApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/es/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  langReducer,
  userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    // Use the original reducer, not the one wrapped with combineReducers
    persistedReducer,
    loadingReducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(authApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
