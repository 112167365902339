import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/dashboardStyles.module.scss';

const Appointments = () => {
    const { t } = useTranslation();

    const RenderModifyButton = ({ item }: any) => {
        return (
            <>
                {
                    item.status !== 'declined' &&
                    <button className={item.status === 'done' ? 'leaveReviewButton' : 'cancelButton'}>
                        {item.status === 'done' ? t('leaveReview') : t('cancel')}
                    </button>
                }
            </>
        )
    }

    const loadColor = (status: string) => {
        switch (status) {
            case 'done':
                return '#303030';
            case 'confirmed':
                return '#25A31A';
            case 'pending':
                return '#C9942F';
            case 'declined':
                return '#EC2454';
            default: return '#303030'
        }
    }

    return (
        <div className='pt-3'>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 mb-4">
                <h5 className='defaultHeader w-25'>{t('upcomingApp')}</h5>
                <h5 className='defaultHeader'>{t('status')}</h5>
                <h5 className='defaultHeader w-25 text-end'>{t('modify')}</h5>
            </div>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3">
                <div className="d-flex flex-column w-25">
                    <h5 className='defaultHeader'>{t('name')}</h5>
                    <span className={styles.desc}>Arger Pjetri</span>
                </div>
                <span style={{ color: loadColor('done') }} className={styles.status}>{t('done')}</span>
                <div className='w-25 text-end'>
                    <RenderModifyButton item={{ status: 'done' }} />
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 pt-4">
                <div className="d-flex flex-column w-25">
                    <h5 className='defaultHeader'>{t('phone')}</h5>
                    <span className={styles.desc}>+355675885088</span>
                </div>
                <span style={{ color: loadColor('confirmed') }} className={styles.status}>{t('confirmed')}</span>
                <div className='w-25 text-end'>
                    <RenderModifyButton item={{ status: 'pending' }} />
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 pt-4">
                <div className="d-flex flex-column w-25">
                    <h5 className='defaultHeader'>{t('emailAddr')}</h5>
                    <span className={styles.desc}>test@test.com</span>
                </div>
                <span style={{ color: loadColor('declined') }} className={styles.status}>{t('declined')}</span>
                <div className='w-25 text-end'>
                    <RenderModifyButton item={{ status: 'declined' }} />
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 pt-4">
                <div className="d-flex flex-column w-25">
                    <h5 className='defaultHeader'>{t('emailAddr')}</h5>
                    <span className={styles.desc}>test@test.com</span>
                </div>
                <span style={{ color: loadColor('pending') }} className={styles.status}>{t('pending')}</span>
                <div className='w-25 text-end'>
                    <RenderModifyButton item={{ status: 'pending' }} />
                </div>
            </div>
        </div>
    )
}

export default Appointments;