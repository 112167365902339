import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type LanguageTypes = 'en' | 'al';
export interface LangState {
  lang: LanguageTypes
}

const initialState: LangState = {
  lang: 'en',
}

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    updateLanguage: (state, action) => {
      state.lang = action.payload
    },
  },
})

export const { updateLanguage } = langSlice.actions

export default langSlice.reducer