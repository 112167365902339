import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="#7A7A7A"
        stroke="#7A7A7A"
        strokeWidth="0.1"
        d="M6.5.95C3.44.95.95 3.44.95 6.5c0 3.06 2.49 5.55 5.55 5.55 3.06 0 5.55-2.49 5.55-5.55C12.05 3.44 9.56.95 6.5.95zm0 10.313A4.768 4.768 0 011.737 6.5 4.768 4.768 0 016.5 1.737 4.768 4.768 0 0111.262 6.5 4.768 4.768 0 016.5 11.262z"
      ></path>
      <path
        fill="#7A7A7A"
        d="M7.681 8.3a1.462 1.462 0 01-.837.262c-.633 0-1.182-.418-1.48-1.03H6.5a.344.344 0 000-.688H5.154a2.45 2.45 0 01-.029-.344c0-.118.013-.232.029-.344h1.69a.344.344 0 000-.687h-1.48c.298-.614.847-1.032 1.48-1.032.3 0 .581.09.837.264a.344.344 0 10.388-.568 2.17 2.17 0 00-1.225-.383c-1.007 0-1.87.713-2.228 1.719h-.522a.344.344 0 000 .687h.374c-.013.114-.03.226-.03.344 0 .117.017.23.03.344h-.374a.344.344 0 000 .687h.522c.358 1.006 1.22 1.719 2.228 1.719a2.17 2.17 0 001.225-.383.344.344 0 10-.388-.568z"
      ></path>
    </svg>
  );
}

export default Icon;