import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <g clipPath="url(#clip0_499_230)">
        <path
          fill="#575757"
          d="M17 30.554c-5.728-1.871-9.668-7.252-9.668-13.33V9.24c3.74-.529 6.976-2.072 9.668-4.609 2.692 2.537 5.928 4.08 9.668 4.609v7.984c0 6.078-3.94 11.459-9.668 13.33z"
        ></path>
        <path
          fill="#fff"
          d="M15.112 17.358a.664.664 0 00-.94.94l1.415 1.414c.26.26.68.26.94 0l3.3-3.3a.664.664 0 00-.94-.94l-2.83 2.831-.945-.945z"
        ></path>
        <path
          fill="#fff"
          d="M9.668 17.592c0 4.043 3.29 7.332 7.332 7.332 4.043 0 7.332-3.289 7.332-7.332 0-4.042-3.29-7.331-7.332-7.331-4.043 0-7.332 3.289-7.332 7.331zm13.336 0A6.01 6.01 0 0117 23.596a6.01 6.01 0 01-6.004-6.004A6.01 6.01 0 0117 11.59a6.01 6.01 0 016.004 6.003z"
        ></path>
        <path
          fill="#575757"
          d="M29.347 6.112c-4.973-.085-8.84-2-11.822-5.854a.664.664 0 00-1.05 0c-2.983 3.854-6.85 5.77-11.822 5.854A.664.664 0 004 6.776v10.448c0 3.847 1.237 7.495 3.575 10.55a17.212 17.212 0 009.252 6.203c.11.03.23.032.346 0a17.212 17.212 0 009.252-6.203 17.212 17.212 0 003.574-10.55V6.776a.664.664 0 00-.652-.664zm-.676 11.112c0 7.164-4.79 13.48-11.671 15.423-6.881-1.942-11.671-8.259-11.671-15.423V7.42c4.807-.23 8.637-2.1 11.67-5.698 3.034 3.598 6.865 5.468 11.672 5.698v9.805z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_499_230">
          <path fill="#fff" d="M0 0H34V34H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
