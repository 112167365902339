import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LoadingState {
    loading: boolean
}

const initialState: LoadingState = {
  loading: false,
}

export const loadingSpinnerSlice = createSlice({
  name: 'loadingSpinner',
  initialState,
  reducers: {
    upadateLoader: (state, action) => {
      state.loading = action.payload
    },
  }
})

export const { upadateLoader } = loadingSpinnerSlice.actions

export default loadingSpinnerSlice.reducer