import React from 'react';
import styles from './styles/home.module.scss';
import "react-multi-carousel/lib/styles.css";
import Speciality from '../../components/client/Speciality';
import HomeBanner1 from '../../components/client/HomeBanner1';
import DoctorsSlider from '../../components/client/DoctorsSlider';
import Testimonials from '../../components/client/Testimonials';
import SearchBar from '../../components/client/SearchBar';
import HomeBanner2 from '../../components/client/HomeBanner2';
import { useNavigate } from 'react-router-dom';
const Home = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='container my-5 d-flex justify-content-center'>
                <SearchBar searchClicked={() => navigate('search')} />
            </div>
            <div className='container-fluid px-md-5 px-sm-2'>
                <div className="specialities my-5">
                    <p className={styles.specialitiesHeading}>Top-searched specialties</p>
                    <div className="container">
                        <div className="row g-2">
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                                <Speciality text='Primary Care'></Speciality>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                                <Speciality text='Dentist'></Speciality>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                                <Speciality text='Gynecologist'></Speciality>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                                <Speciality text='Dermatologist'></Speciality>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                                <Speciality text='Psychiatrist'></Speciality>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                                <Speciality text='Eye Doctor'></Speciality>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <HomeBanner1 />
           <DoctorsSlider />
           <Testimonials />
           <HomeBanner2 />
        </>

    )
}

export default Home;