import { Backdrop, Box, Fade, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { Modal as MaterialModal } from '@mui/material';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';

interface Type {
    component: ReactNode,
    open: boolean,
    closeModal: Function
}

const Modal: FC<Type> = ({ component, open, closeModal }) => {
    const handleClose = () => closeModal();
    const { isMobile } = useCustomMediaQuery();
    return (
        <div>
            <MaterialModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={isMobile ? mobileStyle : style}>
                        {component}
                    </Box>
                </Fade>
            </MaterialModal>
        </div>
    )
}

const mobileStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'var(--white)',
    borderRadius: 1,
    p: 4,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'var(--white)',
    borderRadius: 1,
    p: 4,
  };

export default Modal;