import { NavLink, Outlet, useNavigate } from "react-router-dom";
import useCustomMediaQuery from "../hooks/useCustomMediaQuery";
import styles from './styles/clientStyles.module.scss';
import Logo from '../assets/images/logo.png';
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { updateLanguage } from "../app/slices/langSlice";
import { useTranslation } from "react-i18next";
import { Box, Fade, Popper } from "@mui/material";
import Modal from "../components/shared /Modal";
import SignIn from "../components/client/SignIn";
import { upadateLoader } from "../app/slices/loadingSpinnerSlice";
import { DashboardIcon, LogoutIcon, ProfileIcon } from "../assets/icons";

type userType = 'doctor' | 'patient';

export default function ClientRoute() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        isDesktopOrLaptop,
        isTabletOrMobile,
    } = useCustomMediaQuery();
    const collapseRef = useRef<any>(null);
    const dispatch = useAppDispatch();

    const language = useAppSelector(state => state.persistedReducer.langReducer.lang)
    // const authenticated = localStorage.getItem('token');
    const authenticated = true;

    const [lang, setLang] = useState<string>(language);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const [userType, setUserType] = useState<userType>();


    const menuItemClick = (target: string) => {
        navigate(target);
    }
    const selectLang = (language: string) => {
        dispatch(updateLanguage(language));
        setLang(language)
    }

    const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
            setAnchorEl(event?.currentTarget);
        }
        setOpen((previousOpen) => !previousOpen);
    };
    const signOut = () => {
        console.log('Sign out clicked');
    }
    return (
        <>
            <nav style={{ backgroundColor: 'var(--white' }} className="navbar navbar-expand-lg">
                <div className="container">
                    <div className="d-lg-flex justify-content-lg-betweeen w-100">
                        {
                            isTabletOrMobile &&
                            <div className="d-flex justify-content-between w-100">
                                <NavLink to="/" onClick={() => menuItemClick('/')}>
                                    <img src={Logo} alt="" />
                                </NavLink>
                                <div className="d-flex align-items-center">
                                    <div className="dropdown me-4">
                                        <a style={{ textTransform: 'capitalize' }} className={`nav-link dropdown-toggle ${styles.navLink}`} data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">{lang}</a>
                                        <ul style={{ minWidth: 50 }} className="dropdown-menu">
                                            <li><p className="dropdown-item m-0" onClick={() => selectLang('en')}>En</p></li>
                                            <li><p className="dropdown-item m-0" onClick={() => selectLang('al')}>Al</p></li>
                                        </ul>
                                    </div>
                                    <button ref={collapseRef} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="d-flex justify-content-between align-items-center w-100">
                            {
                                !isTabletOrMobile &&
                                <NavLink to="/" className="d-flex align-items-center" onClick={() => menuItemClick('/')}>
                                    <img src={Logo} alt="" />
                                </NavLink>
                            }
                            <div className="collapse navbar-collapse justify-content-md-end" id="navbarSupportedContent">
                                <ul className="navbar-nav mb-2 mb-lg-0">
                                    <li className="nav-item pe-2 border-end">
                                        <NavLink to="search" onClick={() => menuItemClick('search')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={({ isActive }) =>
                                            isActive ? `nav-link ${styles.navLink} ${styles.active}` : `nav-link ${styles.navLink} `
                                        }>
                                            Browse
                                        </NavLink>
                                    </li>
                                    <li className="nav-item px-2 border-end">
                                        <NavLink to="help" onClick={() => menuItemClick('help')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={({ isActive }) =>
                                            isActive ? `nav-link ${styles.navLink} ${styles.active}` : `nav-link ${styles.navLink}`
                                        }>
                                            Help
                                        </NavLink>
                                    </li>
                                    <li className="nav-item px-2 border-end">
                                        <NavLink to="register-doctor" onClick={() => menuItemClick('register-doctor')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={({ isActive }) =>
                                            isActive ? `nav-link ${styles.navLink} ${styles.active}` : `nav-link ${styles.navLink}`
                                        }>
                                            Add Listing
                                        </NavLink>
                                    </li>
                                    <li className="nav-item dropdown px-2 border-end">
                                        <a style={{ textTransform: 'capitalize' }} className={`nav-link dropdown-toggle ${styles.navLink}`} data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">{lang}</a>
                                        <ul style={{ minWidth: 50 }} className="dropdown-menu">
                                            <li><p className="dropdown-item m-0 pointer" onClick={() => selectLang('en')}>En</p></li>
                                            <li><p className="dropdown-item m-0 pointer" onClick={() => selectLang('al')}>Al</p></li>
                                        </ul>
                                    </li>




                                    {
                                        authenticated ?
                                            <button className={`${styles.navLink} nav-link dropdown-toggle ms-2 d-flex align-items-center`} aria-describedby={'login'} type="button" onClick={handleClick}>
                                                <div className="d-flex align-items-center">
                                                    <ProfileIcon /> <span className="ps-2">Name Surname</span>
                                                </div>
                                            </button>
                                            :
                                            <button className={`${styles.navLink} nav-link dropdown-toggle ms-2`} aria-describedby={'login'} type="button" onClick={handleClick}>
                                                {t('login')} / {t('sign_up')}
                                            </button>
                                    }

                                    <Popper id={'login'} open={open} anchorEl={anchorEl} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Box sx={{ p: 3, borderRadius: 2, backgroundColor: 'var(--white)', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
                                                    {
                                                        authenticated ?
                                                            <>
                                                                <div className="d-flex align-items-center">
                                                                    <NavLink to="client_dashboard" onClick={() => menuItemClick('client_dashboard')}  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={'nav-link'}>
                                                                        <span className={`${styles.navLink} d-flex align-items-center`}>
                                                                            <DashboardIcon />  <span className="ps-3">{t('myDashboard')}</span>
                                                                        </span>
                                                                    </NavLink>
                                                                </div>
                                                                <hr style={{ borderColor: 'var(--gray-primary)' }} />
                                                                <div className="d-flex align-items-center">
                                                                    <NavLink to="sign-out" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={'nav-link'}>
                                                                        <span onClick={() => { handleClick(); signOut() }} className={`${styles.navLink} d-flex align-items-center`}>
                                                                            <LogoutIcon />  <span className="ps-3">{t('signOut')}</span>
                                                                        </span>
                                                                    </NavLink>
                                                                </div>
                                                            </>

                                                            :
                                                            <>
                                                                <div className="d-flex align-items-center">
                                                                    <span className={styles.popoverText}>{t('patients')} </span>
                                                                    <NavLink to="sign-in" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={'nav-link'}>
                                                                        <span onClick={() => { handleClick(); setOpenLogin(true); setUserType('patient') }} className={styles.popoverSubText}>{t('login')}</span>
                                                                    </NavLink>
                                                                    <NavLink to="sign-up" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={'nav-link'}>
                                                                        <span onClick={() => { handleClick(); navigate('register') }} className={`${styles.popoverSubText} pe-0`}>{t('sign_up')}</span>
                                                                    </NavLink>
                                                                </div>
                                                                <hr style={{ borderColor: 'var(--gray-primary)' }} />
                                                                <div className="d-flex align-items-center">
                                                                    <span className={styles.popoverText}>{t('doctors')} </span>
                                                                    <NavLink to="sign-in" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={'nav-link'}>
                                                                        <span onClick={() => { handleClick(); setOpenLogin(true); setUserType('doctor') }} className={`${styles.popoverSubText} pe-0`}>{t('login')}</span>
                                                                    </NavLink>
                                                                </div>
                                                            </>
                                                    }
                                                </Box>
                                            </Fade>
                                        )}
                                    </Popper>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </nav>
            <Modal component={<SignIn user={userType} close={() => setOpenLogin(false)} />} open={openLogin} closeModal={() => setOpenLogin(false)} />
            <Outlet />
        </>
    );
}