import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles/register.module.scss';
import { GoogleIcon } from '../../assets/icons';
import { Divider } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import SignIn from '../../components/client/SignIn';
import Modal from '../../components/shared /Modal';
import { useRegisterMutation } from '../../app/api/authApi';
import { useAppDispatch } from '../../app/hooks';
import { upadateLoader } from '../../app/slices/loadingSpinnerSlice';

type Inputs = {
    first_name: string
    last_name: string
    email: string
    phone: string
    password: string
    reEnterPassword: string
    terms: boolean
}

const Register = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>()
    const [ _register ] = useRegisterMutation();
        const [openLogin, setOpenLogin] = useState<boolean>(false);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        dispatch(upadateLoader(true))
        let body = {
            'account_type': 'patient',
            'first_name': data.first_name,
            'last_name': data.last_name,
            'email': data.email,
            'phone': data.phone,
            'password': data.password
        }
        _register(body).then(res => {
            console.log(res)
            dispatch(upadateLoader(false))
        })

    }

    return (
        <div style={{ minHeight: 'calc(100vh - 60px)' }} className='d-flex justify-content-center align-items-center h-100 pt-4'>
            <div className="container d-flex justify-content-center ">
                <div className="col-sm-12 col-md-8 ">
                    <p className={styles.continueWith}>{t('continueWith')}</p>
                    <div className="d-flex justify-content-center">
                        <button className='secondaryButton mt-3 w-50 px-5' onClick={() => { }}>
                            <span className='ps-1 mx-auto py-2 d-flex align-items-center'>
                                <GoogleIcon />
                                <span className='ps-2'>Google</span>
                            </span>
                        </button>
                    </div>
                    <div className='mt-4'>
                        <Divider>{t('orRegEmail')}</Divider>
                    </div>
                    <div className='mt-4'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-6">
                                    <div className='d-flex flex-column mt-3'>
                                        <label htmlFor="firstName" className='formLabel ps-2 pb-2'>{t('firstName')}*</label>
                                        <input className='basicInput' id='firstName' {...register('first_name', {
                                            required: true
                                        })} />
                                        {errors.first_name && <span className='inputErrorMessage'>{t('firstNameReq')}</span>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='d-flex flex-column mt-3'>
                                        <label htmlFor="lastName" className='formLabel ps-2 pb-2'>{t('lastName')}*</label>
                                        <input className='basicInput' id='lastName' {...register('last_name', {
                                            required: true
                                        })} />
                                        {errors.last_name && <span className='inputErrorMessage'>{t('lastNameReq')}</span>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='d-flex flex-column mt-3'>
                                        <label htmlFor="email" className='formLabel ps-2 pb-2'>{t('email')}*</label>
                                        <input className='basicInput' id='email' {...register('email', {
                                            required: "emailReq",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "emailValid"
                                            }
                                        })} />
                                        {errors.email && <span className='inputErrorMessage'>{t(`${errors.email.message}`)}</span>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='d-flex flex-column mt-3'>
                                        <label htmlFor="phone" className='formLabel ps-2 pb-2'>{t('phone')}*</label>
                                        <input className='basicInput' id='phone' {...register('phone', {
                                            required: true
                                        })} />
                                        {errors.phone && <span className='inputErrorMessage'>{t('phoneReq')}</span>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='d-flex flex-column mt-3'>
                                        <label htmlFor="password" className='formLabel ps-2 pb-2'>{t('password')}*</label>
                                        <input type='password' className='basicInput' id='password' {...register('password', {
                                            required: "passwordReq",
                                            validate: (password: string) => password === watch('reEnterPassword') || "passwordDontMatch"
                                        })} />
                                        {errors.password && <span className='inputErrorMessage'>{t(`${errors.password.message}`)}</span>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='d-flex flex-column mt-3'>
                                        <label htmlFor="reEnterPassword" className='formLabel ps-2 pb-2'>{t('reEnterPassword')}*</label>
                                        <input type='password' className='basicInput' id='reEnterPassword' {...register('reEnterPassword', {
                                            required: "passwordReq"
                                        })} />
                                        {errors.reEnterPassword && <span className='inputErrorMessage'>{t(`${errors.reEnterPassword.message}`)}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5 mb-4 d-flex '>
                                <div className='d-flex w-100 justify-content-center align-items-center'>
                                    <input {...register("terms", { required: true })} type="checkbox" value="true" />
                                    <p className={`${styles.sWarningMessage} m-0 ps-2`}>
                                        {t('iAccept')}
                                        <a className='ms-1 me-1' onClick={() => { }} href="#">{t('privacyPolicy')}</a>
                                        {t('and')}
                                        <a className='ms-1 me-1' onClick={() => { }} href="#">{t('termsAndAgreement')}</a>
                                    </p>
                                </div>
                                {errors.terms && <span className='inputErrorMessage text-center'>{t('termsReq')}</span>}
                            </div>
                            <div className="row d-flex justify-content-center mt-4">
                                <button type="submit" style={{ width: '200px' }} className='defaultButton'>
                                    <span className='w-100 text-center'>
                                        {t('register')}
                                    </span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <p className={`${styles.sWarningMessage} m-0 pb-4 text-center`}>
                                    {t('alreadyRegistered')}
                                    <a className='ms-1 me-1' onClick={() => setOpenLogin(true)} href="#">{t('loginHere')}</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal component={<SignIn user='patient' close={() => setOpenLogin(false)}/>} open={openLogin} closeModal={() => setOpenLogin(false)} />
        </div>
    )
}

export default Register;