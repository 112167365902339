import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles/signIn.module.scss';
import TextInput from '../shared /TextInput';
import { Divider } from '@mui/material';
import { GoogleIcon } from '../../assets/icons';
import { useGoogleLogin } from '@react-oauth/google';
import { useLoginMutation } from '../../app/api/authApi';
import { useAppDispatch } from '../../app/hooks';
import { upadateLoader } from '../../app/slices/loadingSpinnerSlice';
import { useNavigate } from 'react-router-dom';

interface Type {
    user?: 'doctor' | 'patient',
    close: Function
}

const SignIn: FC<Type> = ({ user, close }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [_login]  = useLoginMutation();

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
    });
    
    const login = () => {
        dispatch(upadateLoader(true))
        if(email && password) {
            _login({email, password}).then(res => {
                dispatch(upadateLoader(false))
                console.log(res)
            })
        }
    }

    return (
        <div>
            <h3 className={styles.signIn}>{t('sign_in')}</h3>
            <p className={styles.signInText}>{t('signInText')}</p>
            <TextInput value={email} placeholder='Email' type='email' onChange={(val: any) => {setEmail(val.target.value)}}></TextInput>
            <TextInput value={password} placeholder='Password' type='password' onChange={(val: any) => setPassword(val.target.value)}></TextInput>
            <p className={styles.forgotPassword}>{t('forgotPassword')}</p>
            <div className="d-flex justify-content-center">
                <button className='defaultButton mt-3 w-100 px-5' onClick={login}>
                    <span className='ps-1 mx-auto py-2'>{t('sign_in')}</span>
                </button>
            </div>
            {
                user === 'patient' &&
                <>
                    <div className='my-3'>
                        <Divider>{t('or')}</Divider>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className='secondaryButton mt-3 w-100 px-5' onClick={() => googleLogin()}>
                            <span className='ps-1 mx-auto py-2 d-flex align-items-center'>
                                <GoogleIcon />
                                <span className='ps-2'>Google</span>
                            </span>
                        </button>
                    </div>

                </>
            }
            <div className='mt-3 d-flex justify-content-center'>
                <span>{t('noAcc')}</span>
                <span className={`ps-2 ${styles.createOne}`} onClick={() => {navigate('register'); close()}}>{t('createOne')}</span>
            </div>
        </div>
    )
}

export default SignIn;