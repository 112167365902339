import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <circle
        cx="10.01"
        cy="9.987"
        r="9.01"
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.504"
      ></circle>
      <path
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.504"
        d="M16.277 16.72l3.533 3.524"
      ></path>
    </svg>
  );
}

export default Icon;
