import React from 'react';
import styles from './styles/testimonials.module.scss';
import Quote from '../../assets/images/quote.png';

const Testimonials = () => {
    return (
        <div className={`container-fluid my-5 ${styles.wrapper}`}>
            <div className="container py-5">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={Quote} width={85} height={90} alt="" />
                    <h5 className={styles.quoteText}>The information, the reviews and also knowing the prices are essential in these difficult times we live in.</h5>
                    <p className={styles.quoteAuthor}> Rajan R. <span className={styles.date}>May 2022</span></p>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;