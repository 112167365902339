import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
    token?: string
}

const initialState: UserState = {
    token: "a"
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            return {
                ...state,
                ...action.payload,
              };
        },
    },

})

export const { updateUser } = userSlice.actions
export default userSlice.reducer