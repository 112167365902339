import React, { useState } from 'react';
import Rocket from '../../assets/images/rocket.png'
import { useTranslation } from 'react-i18next';
import styles from './styles/registerDoctor.module.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import SignIn from '../../components/client/SignIn';
import Modal from '../../components/shared /Modal';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, css, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { upadateLoader } from '../../app/slices/loadingSpinnerSlice';
import { useRegisterMutation } from '../../app/api/authApi';

type Inputs = {
    first_name: string
    last_name: string
    email: string
    phone: string
    password: string
    reEnterPassword: string
    speciality: Array<any>
    state: string
    city: string
    cost: number
    terms: boolean
}

const specialities = [
    { name: 'Oliver Hansen', checked: false },
    { name: 'Van Henry', checked: false },
    { name: 'April Tucker', checked: false },
    { name: 'Ralph Hubbard', checked: false },
    { name: 'Omar Alexander', checked: false },
    { name: 'Carlos Abbott', checked: false },
    { name: 'Miriam Wagner', checked: false },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const RegisterDoctor = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [_register] = useRegisterMutation();
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<Inputs>({
        defaultValues: {
            speciality: []
        }
    })
    const [openLogin, setOpenLogin] = useState<boolean>(false);

    const returnSpecialities = () => {
        let text = "";
        watch('speciality').map(sp => {
            text = text + sp + ', '
        })
        text = text.slice(0, -2);
        return text
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        dispatch(upadateLoader(true))
        let body: any = {
            'account_type': 'doctor',
            'first_name': data.first_name,
            'last_name': data.last_name,
            'email': data.email,
            'phone': data.phone,
            'password': data.password,
            'country_id': data.state,
            'city_id': data.city,
            'specialization_ids': data.speciality
        }
        if (data.cost) {
            body.simple_visit_cost = data.cost
        }
        _register(body).then(res => {
            console.log(res)
            dispatch(upadateLoader(false))
        })

    }


    return (
        <div className='container'>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-6 d-flex justify-content-center">
                    <div className="d-flex flex-column w-50 align-items-center mt-5">
                        <img src={Rocket} width={150} height={150} alt="" />
                        <h5 className={`${styles.getStarted} mt-4`}>{t('getStarted')}</h5>
                        <p className={`${styles.getStartedContent}`}>{t('getStartedContent')}</p>
                    </div>
                </div>
                <div className={`${styles.formWrapper} col-sm-12 col-md-6`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-6">
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="firstName" className='formLabel ps-2 pb-2'>{t('firstName')}*</label>
                                    <input className='basicInput' id='firstName' {...register('first_name', {
                                        required: true
                                    })} />
                                    {errors.first_name && <span className='inputErrorMessage'>{t('firstNameReq')}</span>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="lastName" className='formLabel ps-2 pb-2'>{t('lastName')}*</label>
                                    <input className='basicInput' id='lastName' {...register('last_name', {
                                        required: true
                                    })} />
                                    {errors.last_name && <span className='inputErrorMessage'>{t('lastNameReq')}</span>}
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <FormControl fullWidth>
                                    <label htmlFor="speciality" className='formLabel ps-2 pb-2'>{t('speciality')}*</label>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={watch('speciality')}
                                        {...register('speciality', { required: true })}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={returnSpecialities}
                                        sx={
                                            {
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                '.MuiInputBase-input': {
                                                    padding: "10px 20px",
                                                    width: '100%'
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#D5D4D2',
                                                },
                                            }}
                                        MenuProps={MenuProps}
                                    >
                                        {specialities.map((speciality) => (
                                            <MenuItem key={speciality.name} value={speciality.name}>
                                                <Checkbox checked={watch('speciality').findIndex(sp => sp === speciality.name) !== -1} />
                                                <ListItemText primary={speciality.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.speciality && <span className='inputErrorMessage'>{t('specialitiesReq')}</span>}
                                </FormControl>
                            </div>
                            <div className="col-12 mt-3">
                                <FormControl fullWidth>
                                    <label htmlFor="state" className='formLabel ps-2 pb-2'>{t('state')}*  <br />
                                        <span className='labelWarning'>{t('stateWarning')}</span>
                                    </label>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        {...register('state', { required: true })}
                                        label={t('state')}
                                        sx={
                                            {
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                '.MuiInputBase-input': {
                                                    padding: "10px 20px",
                                                    width: '100%'
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#D5D4D2',
                                                },
                                            }}
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                    {errors.state && <span className='inputErrorMessage'>{t('stateReq')}</span>}
                                </FormControl>
                            </div>
                            <div className="col-12 mt-3">
                                <FormControl fullWidth>
                                    <label htmlFor="city" className='formLabel ps-2 pb-2'>{t('city')}*  <br />
                                        <span className='labelWarning'>{t('cityWarning')}</span>
                                    </label>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        {...register('city', { required: true })}
                                        label={t('city')}
                                        sx={
                                            {
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                '.MuiInputBase-input': {
                                                    padding: "10px 20px",
                                                    width: '100%'
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#D5D4D2',
                                                },
                                            }}
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                    {errors.city && <span className='inputErrorMessage'>{t('cityReq')}</span>}
                                </FormControl>
                            </div>
                            <div className="col-12 mt-3">
                                <FormControl fullWidth>
                                    <label htmlFor="cost" className='formLabel ps-2 pb-2'>{t('cost')}*
                                        <span className='labelWarning'> {t('costWarning')}</span>
                                    </label>
                                    <input type='number' className='basicInput inputHasLeft position-relative' id='cost' {...register('cost')} />
                                    <div style={{ top: '36px', color: '#454C5F80', fontSize: 15 }} className='px-3 py-1 position-absolute left-0 border-end'>EUR</div>
                                </FormControl>
                            </div>
                            <div className="col-12 mt-3">
                                <FormControl fullWidth>
                                    <label htmlFor="phone" className='formLabel ps-2 pb-2'>{t('phone')}* </label>
                                    <input type='tel' className='basicInput inputHasLeft position-relative' id='phone' {...register('phone', { required: true })} />
                                    <div style={{ top: '36px', color: '#454C5F80', fontSize: 15 }} className='px-2 py-1 position-absolute left-0 border-end'>(+355)</div>
                                    {errors.phone && <span className='inputErrorMessage'>{t('phoneReq')}</span>}
                                </FormControl>
                            </div>
                            <div className="col-12">
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="password" className='formLabel ps-2 pb-2'>{t('password')}*</label>
                                    <input type='password' className='basicInput' id='password' {...register('password', {
                                        required: "passwordReq",
                                        validate: (password: string) => password === watch('reEnterPassword') || "passwordDontMatch"
                                    })} />
                                    {errors.password && <span className='inputErrorMessage'>{t(`${errors.password.message}`)}</span>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="reEnterPassword" className='formLabel ps-2 pb-2'>{t('reEnterPassword')}*</label>
                                    <input type='password' className='basicInput' id='reEnterPassword' {...register('reEnterPassword', {
                                        required: "passwordReq"
                                    })} />
                                    {errors.reEnterPassword && <span className='inputErrorMessage'>{t(`${errors.reEnterPassword.message}`)}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4 mb-4 d-flex '>
                            <div className='d-flex w-100 ps-3 justify-content-start align-items-center'>
                                <input {...register("terms", { required: true })} type="checkbox" value="true" />
                                <p className={`${styles.sWarningMessage} m-0 ps-2`}>
                                    {t('iAccept')}
                                    <a className='ms-1 me-1' onClick={() => { }} href="#">{t('privacyPolicy')}</a>
                                    {t('and')}
                                    <a className='ms-1 me-1' onClick={() => { }} href="#">{t('termsAndAgreement')}</a>
                                </p>
                            </div>
                            {errors.terms && <span className='inputErrorMessage text-center'>{t('termsReq')}</span>}
                        </div>
                        <div className="row d-flex justify-content-center mt-4 px-2">
                            <button type="submit" style={{ width: '100%' }} className='defaultButton'>
                                <span className='w-100 text-center'>
                                    {t('register')}
                                </span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <p className={`${styles.sWarningMessage} m-0 pb-4 text-center`}>
                                {t('alreadyHaveAccount')}
                                <a className='ms-1 me-1' onClick={() => setOpenLogin(true)} href="#">{t('login')}</a>
                            </p>
                        </div>
                    </form>


                </div>
            </div>
            <Modal component={<SignIn user='doctor' close={() => setOpenLogin(false)} />} open={openLogin} closeModal={() => setOpenLogin(false)} />
        </div>
    )
}

export default RegisterDoctor;