import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/dashboardStyles.module.scss';

const Profile = () => {
    const { t } = useTranslation();
    
    return(
        <div className='pt-3'>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3">
                <div className="d-flex flex-column">
                    <h5 className='defaultHeader'>{t('name')}</h5>
                    <span className={styles.desc}>Arger Pjetri</span>
                </div>
                <span className={styles.edit}>{t('edit')}</span>
            </div>
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 pt-4">
                <div className="d-flex flex-column">
                    <h5 className='defaultHeader'>{t('phone')}</h5>
                    <span className={styles.desc}>+355675885088</span>
                </div>
                <span className={styles.edit}>{t('edit')}</span>
            </div>  
            <div className="d-flex justify-content-between align-items-start border-bottom pb-3 pt-4">
                <div className="d-flex flex-column">
                    <h5 className='defaultHeader'>{t('emailAddr')}</h5>
                    <span className={styles.desc}>test@test.com</span>
                </div>
                <span className={styles.edit}>{t('edit')}</span>
            </div>  
            <div className="border-bottom pb-3 pt-4">
                <h5 className='defaultHeader'>{t('password')}</h5>
                <button className='secondaryButton my-3'>{t('changePass')}</button>
            </div>  
        </div>
    )
}

export default Profile;