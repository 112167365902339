import react from 'react';
import styles from './styles/doctorcard.module.scss';
import DoctorImage from '../../assets/images/DoctorCardImage.png';
import Star from '../../assets/images/Star.png';
import { PhoneIcon, EuroIcon, PinIcon, ProfileIcon } from '../../assets/icons';
import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DoctorCard = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className={styles.wrapper}>
            <div className="row">
                <div className="col-sm-12 col-md-9">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <img src={DoctorImage} className={styles.doctorImg} alt="" />
                        </div>
                        <div className="col-sm-12 col-md-8 d-flex flex-column mt-3 mt-md-0">
                            <h3 className={`${styles.name} m-0`}>Dr. Lorena Dali</h3>
                            <p className={`${styles.proffesion} mb-1`}>Dermatologe</p>
                            <div className="d-flex flex-row align-items-center ">
                                <img src={Star} className='me-1' height={17} alt="" />
                                <span className={styles.rating}>4.3</span>
                                <span className={styles.total}>(23 reviews)</span>
                            </div>
                            <div className="flex align-items-center">
                                <PhoneIcon />
                                <span className={styles.phone}>View phone number</span>
                            </div>
                            <div className="flex align-items-center">
                                <EuroIcon />
                                <span className={`${styles.phone} ${styles.gray}`}>View phone number</span>
                                <span className={styles.phone}>View phone number</span>
                            </div>
                            <div className="flex align-items-center">
                                <Chip label="Chip Outlined" style={{ marginRight: 5, marginTop: 5, borderColor: 'var(--blue2)', color: 'var(--blue2)' }} variant="outlined" />
                                <Chip label="Chip " style={{ marginRight: 5, marginTop: 5, borderColor: 'var(--blue2)', color: 'var(--blue2)' }} variant="outlined" />
                                <Chip label="Chip Outlined" style={{ marginRight: 5, marginTop: 5, borderColor: 'var(--blue2)', color: 'var(--blue2)' }} variant="outlined" />
                            </div>
                            <div className="flex align-items-center pt-1">
                                <PinIcon />
                                <span className={`${styles.phone} ${styles.gray}`}>Spitali Amerikan 3</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3 mt-3 mt-md-0">
                    <div className="border-start ps-3">
                        <h5 className={styles.availability}>Next availability</h5>
                        <p className={styles.availability} style={{ fontFamily: 'Satoshi' }}>11/12/2023 16:00</p>
                    </div>
                    <div>
                        <button className='defaultButton'>
                            <div onClick={() => navigate('/doctor', {state: {id: '123'}})} className="d-flex align-items-center">
                                <ProfileIcon />
                                <span className='ms-2'>{t('view_profile')}</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="col-12">
                    <hr />
                    <p className={styles.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderi. Ut enim ad minim veniam, quis nostrud. eprehenderi. Ut enim ad minim veniam, quis nostrud, onsectetur adipiscing elit. </p>
                </div>
            </div>
        </div>
    )
}

export default DoctorCard;