
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      fill="none"
      viewBox="0 0 11 11"
    >
      <g clipPath="url(#clip0_561_712)">
        <path
          fill="#5B7EFB"
          d="M10.827 8.27c-.018-.016-2.077-1.49-2.636-1.393-.268.048-.422.23-.73.597-.049.06-.168.2-.26.301A4.71 4.71 0 014.23 4.804c.1-.092.242-.212.303-.263.364-.305.547-.46.595-.728.097-.556-1.377-2.617-1.392-2.635a.787.787 0 00-.587-.332C2.552.846.846 3.06.846 3.432c0 .021.031 2.223 2.746 4.984 2.758 2.711 4.96 2.743 4.981 2.743.373 0 2.586-1.706 2.586-2.303a.786.786 0 00-.332-.587zm-2.292 2.2c-.3-.026-2.147-.27-4.457-2.538C1.798 5.61 1.56 3.757 1.536 3.47a9.3 9.3 0 011.618-1.912l.055.062c.478.652.89 1.35 1.229 2.084-.11.11-.227.215-.35.313a3.45 3.45 0 00-.52.489l-.083.117.025.14c.073.316.184.621.332.909a5.4 5.4 0 002.491 2.49c.288.149.593.26.908.333l.141.025.117-.084c.181-.156.346-.331.49-.522.109-.128.253-.3.307-.348.736.339 1.436.75 2.09 1.23l.061.054a9.292 9.292 0 01-1.912 1.618v.001z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_561_712">
          <path fill="#fff" d="M0 0H11V11H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
