import React, { CSSProperties, useEffect } from 'react';
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './routes/root';
import ErrorPage from './routes/error-page';
import Login from './modules/auth/Login';
import ClinicRoute from './routes/clinic-route';
import AdminRoute from './routes/admin-route';
import ClinicDashboard from './modules/clinic/components/Dashboard';
import AdminDashboard from './modules/admin/components/Dashboard';
import ClientRoute from './routes/client-route';
import 'bootstrap/dist/js/bootstrap.bundle';
import Home from './modules/client/Home';
import Help from './modules/client/Help';
import AddListing from './modules/client/RegisterDoctor';
import Contact from './modules/client/Contact';
import Browse from './modules/client/Browse';
import DoctorScreen from './modules/client/screens/DoctorScreen';
import { useAppSelector } from './app/hooks';
import { changeLanguage } from 'i18next';
import BounceLoader from "react-spinners/BounceLoader";
import Register from './modules/client/Register';
import ClientDashboard from './modules/client/Dashboard';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'auth',
        element: <Login />,
        children: [
          {
            path: 'login',
            element: <Login />
          },
          {
            path: 'register',
            element: <Login />
          },
          {
            path: 'forgot-password',
            element: <Login />
          }
        ]
      },
      {
        path: 'clinic',
        element: <ClinicRoute />,
        children: [
          {
            path: '',
            element: <ClinicDashboard />
          }
        ]
      },
      {
        path: 'admin',
        element: <AdminRoute />,
        children: [
          {
            path: '',
            element: <AdminDashboard />
          }
        ]
      },
      {
        path: '/',
        element: <ClientRoute />,
        children: [
          {
            path: '',
            element: <Home />
          },
          {
            path: 'help',
            element: <Help />
          },
          {
            path: 'register-doctor',
            element: <AddListing />
          },
          {
            path: 'contact',
            element: <Contact />
          },
          {
            path: 'search',
            element: <Browse />,
          },
          {
            path: '/doctor',
            element: <DoctorScreen />
          },
          {
            path: 'register',
            element: <Register />
          },
          {
            path: 'client_dashboard',
            element: <ClientDashboard />
          }
        ]
      }
    ]
  },
]);
function App() {
  const lang = useAppSelector(state => state.persistedReducer.langReducer.lang);
  const loading = useAppSelector(state => state.loadingReducer.loading)
  useEffect(() => {
    changeLanguage(lang)
  }, [lang])
  const override: CSSProperties = {
    display: "block",
    borderColor: "#36d7b7",
  };
  return (
    <>
      <RouterProvider router={router} />
      {
        loading &&
        <div style={{ backgroundColor: 'white', zIndex: 99999 }} className='position-absolute d-flex align-items-center justify-content-center w-100 h-100 top-0 left-0 right-0 bottom-0'>
          <BounceLoader
            color={'#36d7b7'}
            loading={loading}
            cssOverride={override}
            size={90}
            speedMultiplier={2}
            aria-label="loader"
            data-testid="BounceLoader"
          />
        </div>
      }
    </>
  );
}

export default App;
