import React, { FC } from 'react';

interface Type {
    text: string
}

const Title: FC<Type> = ({ text }) => {
    return (
        <>
            <h5 className='mb-2' style={{ fontFamily: 'Satoshi-Medium', fontSize: 18, lineHeight: '21px', color: 'var(--text-secondary)' }}>{text}</h5>
            <hr style={{borderColor: 'var(--gray-primary)'}} />
        </>

    )
}

export default Title;