import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#303030"
        fillRule="evenodd"
        d="M7.88 1.72a6.289 6.289 0 103.893 11.228l2.702 2.702a.74.74 0 001.046-1.046l-2.702-2.702A6.289 6.289 0 007.879 1.72zM3.07 8.01a4.809 4.809 0 119.619 0 4.809 4.809 0 01-9.618 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
