import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      fill="none"
      viewBox="0 0 14 17"
    >
      <path
        stroke="#28303F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.178"
        d="M11.993 9.638l1.016-1.016a.785.785 0 000-1.11l-1.016-1.015"
      ></path>
      <path
        stroke="#28303F"
        strokeLinecap="round"
        strokeWidth="1.178"
        d="M12.779 8.067H8.067M1 12.203V4.35m9.423 7.853a1.57 1.57 0 01-1.57 1.57H5.71m4.712-9.423a1.57 1.57 0 00-1.57-1.57H5.71M1.7 14.24l1.57 1.046c1.044.696 2.442-.052 2.442-1.306V2.572c0-1.254-1.398-2.002-2.441-1.307L1.7 2.313A1.57 1.57 0 001 3.62v9.312c0 .526.262 1.016.7 1.307z"
      ></path>
    </svg>
  );
}

export default Icon;