import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#FED337"
        d="M14.866 0H4.134a1.75 1.75 0 00-1.75 1.75v15.87c.003 1.252 1.532 1.839 2.401.96l4.092-4.09a.886.886 0 011.246 0l4.091 4.09c.87.88 2.399.291 2.402-.96V1.75A1.75 1.75 0 0014.866 0z"
      ></path>
    </svg>
  );
}

export default Icon;
