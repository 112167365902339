import React from "react";

function Icon() {
  return (
    <svg
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <g clipPath="url(#clip0_509_18)">
        <path
          fill="#167EE6"
          d="M16.358 7.022H9.424a.554.554 0 00-.554.554v2.215c0 .306.248.555.554.555h3.905a5.213 5.213 0 01-2.244 2.63l1.665 2.881C15.421 14.313 17 11.602 17 8.568c0-.432-.032-.74-.096-1.088a.557.557 0 00-.546-.458z"
        ></path>
        <path
          fill="#12B347"
          d="M8.5 13.674a5.173 5.173 0 01-4.475-2.59l-2.882 1.662a8.489 8.489 0 0011.607 3.115v-.004l-1.665-2.882a5.135 5.135 0 01-2.585.699z"
        ></path>
        <path
          fill="#0F993E"
          d="M12.75 15.861v-.004l-1.665-2.882a5.136 5.136 0 01-2.585.699V17a8.485 8.485 0 004.25-1.139z"
        ></path>
        <path
          fill="#FFD500"
          d="M3.326 8.5c0-.942.257-1.823.699-2.585L1.143 4.254A8.412 8.412 0 000 8.5c0 1.546.415 2.997 1.143 4.246l2.882-1.661A5.136 5.136 0 013.326 8.5z"
        ></path>
        <path
          fill="#FF4B26"
          d="M8.5 3.326a5.15 5.15 0 013.285 1.18c.22.181.541.168.743-.034l1.57-1.57a.559.559 0 00-.032-.816A8.475 8.475 0 008.5 0a8.489 8.489 0 00-7.357 4.254l2.882 1.661A5.173 5.173 0 018.5 3.326z"
        ></path>
        <path
          fill="#D93F21"
          d="M11.785 4.505c.22.182.541.169.743-.033l1.57-1.57a.559.559 0 00-.032-.816A8.474 8.474 0 008.5 0v3.326a5.15 5.15 0 013.285 1.18z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_509_18">
          <path fill="#fff" d="M0 0H17V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
