import React from 'react';
import styles from './styles/doctors.module.scss';
import DoctorImg from '../../assets/images/doctor.png';
import Verified from '../../assets/images/verified.png';
import Star from  '../../assets/images/Star.png';

const Doctor = () => {

    return (
        <div className='d-flex flex-column align-items-center'>
            <img className={styles.doctorImg} src={DoctorImg} alt="" />
            <div className="content text-center">
                <h3 className={`${styles.name} d-flex align-items-center mt-1`}>Dr. Lorena Dali <img src={Verified} className='ms-2' width={15} alt="" /></h3>
                <p className={`mb-2 ${styles.proffesion}`}>Dentiste</p>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <img src={Star} className='me-1' height={17} alt="" />
                    <span className={styles.rating}>4.3</span>
                    <span className={styles.total}>(23)</span>
                </div>
            </div>
        </div>
    )
}

export default Doctor;