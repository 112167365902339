import React, { useState } from 'react';
import styles from './styles/dashboardStyles.module.scss';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import Profile from './screens/Profile';
import Appointments from './screens/Appointments';
import SavedDoctors from './screens/SavedDoctors';

const ClientDashboard = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<string>('profile');

    const changeActiveTab = (event: any, value: string) => {
        if (value) {
            setActiveTab(value);
        }
    }
    return (
        <div className='container'>
            <div className="row py-5 px-3">
                <h5 className={styles.heading}>{t('dashboard')}</h5>
                <div className="row pt-3 px-3">
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider',  padding: 0 }}>
                            <Tabs
                                sx={{
                                    ".MuiButtonBase-root.Mui-selected": {
                                        color: '#303030',
                                    },
                                    ".MuiTabs-flexContainer": {
                                        overflowX: 'auto'
                                    }
                                }}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#0C0C0C",
                                    }
                                }} value={activeTab} onChange={changeActiveTab} aria-label="basic tabs example">
                                <Tab label={`${t('profile')}`} sx={{ color: 'var(--text-secondary)' }} value={'profile'} />
                                <Tab label={`${t('appointments')}`} sx={{ color: 'var(--text-secondary)' }} value={'appointments'} />
                                <Tab label={`${t('savedDoctors')}`} sx={{ color: 'var(--text-secondary)' }} value={'saved'} />
                            </Tabs>
                        </Box>
                        <div className="pt-4 px-0">
                            {activeTab === 'profile' && <Profile />}
                            {activeTab == 'appointments' && <Appointments />}
                            {activeTab == 'saved' && <SavedDoctors />}
                        </div>
                    </>
                </div>
            </div>
        </div>
    )
}

export default ClientDashboard;