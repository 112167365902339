import react from 'react';
import styles from './styles/search.module.scss';
import SearchBar from '../../components/client/SearchBar';
import DoctorCard from '../../components/client/DoctorCard';
import { useTranslation } from 'react-i18next';

const Search = () => {
    const { t } = useTranslation();
    const doctors = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const searchDoctors = () => {
        // logic goes here

    }
    return (
        <>
            <div className='container my-5 d-flex justify-content-center'>
                <SearchBar searchClicked={searchDoctors} />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        aa
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <h5 className='defaultHading'>{t('our_doctors')}</h5>
                        <div className="pt-3">
                            {
                                doctors.map(doc =>
                                    <DoctorCard />
                                )
                            }
                            <DoctorCard />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Search;