import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import styles from './styles/TextInput.module.scss';
import { useTranslation } from 'react-i18next';

type InputTypes = 'text' | 'password' | 'email';

interface Type {
    value: string,
    placeholder?: string,
    onChange: ChangeEventHandler<HTMLInputElement>,
    type: InputTypes,
    label?: string
}
/**
 * value, placeholder, onChange, type, label
 * @returns Input Field
 */
const TextInput:FC<Type> = ({value, placeholder = '', onChange, type, label}) => {
    const { t } = useTranslation();
    const [inputType, setInputType] = useState<InputTypes>(type)
    const [showPass, setShowPass] = useState(false);
    useEffect(() => {
        if(type === 'password') {
            showPass? setInputType('text') : setInputType('password')
        }
    },[showPass])
    return (
        <div className={styles.wrapper}>
            {
                label && <label htmlFor={label}>{label}</label>
            }
            <input id={label} className={`${styles.input}`} placeholder={placeholder} type={inputType} value={value} onChange={onChange} />
            { type === 'password' &&
                <span onClick={() => setShowPass(!showPass)} className={styles.showPass}>{showPass? t('hide'): t('show')}</span>  
            } 
        </div>
    )
}

export default TextInput;