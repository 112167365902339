import React from 'react';

const Login = () => {
    return(
        <div>
            Login atqifja
        </div>
    )
}

export default Login;