import React from 'react';
import styles from './styles/banner2.module.scss';
import Banner2 from '../../assets/images/doctorBanner.png';

const HomeBanner2 = () => {
    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-md-6 col-sm-12 px-3 pb-4 pb-md-0">
                    <h5 className={styles.heading}>Are you a healthcare provider that wants to increase your patient leads?</h5>
                    <ul className='pt-md-5 pt-xs-2'>
                        <li className={`mb-lg-4 ${styles.listItem}`}>Reach new local patients looking for a provider.</li>
                        <li className={`mb-lg-4 ${styles.listItem}`}>Reduce your marketing expenses since we take care of them.</li>
                        <li className={`${styles.listItem}`}>Boost your credibility across online and local platforms with verified reviews.</li>
                    </ul>
                    <button className='defaultButton'>List your practice</button>
                </div>
                <div className="col-md-6 col-sm-12">
                    <img src={Banner2} width={'100%'} alt="" />
                </div>
            </div>
        </div>
    )
}

export default HomeBanner2;