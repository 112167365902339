import React, { FC } from 'react';
import styles from './styles/searchbar.module.scss';
import { PinIcon, SearchIcon, SearchIcon2 } from '../../assets/icons';
import { Autocomplete, Divider } from '@mui/material';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { useTranslation } from 'react-i18next';

interface Type {
    searchClicked: Function
}

const SearchBar: FC<Type> = ({ searchClicked }) => {
    const { t } = useTranslation();
    const options = ['Option 1', 'Option 2'];
    const { isMobile } = useCustomMediaQuery();

    return (
        <>

            {
                !isMobile ?
                    <div className={`${styles.wrapper} d-flex justify-content-between`}>
                        <div className='d-flex w-100'>
                            <div className='d-flex align-items-center w-50 px-3'>
                                <SearchIcon2 />
                                <Autocomplete
                                    sx={{
                                        display: 'inline-block',
                                        '& input': {
                                            width: '100%',
                                            border: 'none',
                                            outlineWidth: 0
                                        }
                                    }}
                                    fullWidth={true}
                                    id="custom-input-demo"
                                    options={options}
                                    renderInput={(params) => (
                                        <div className='ms-2' ref={params.InputProps.ref}>
                                            <input type="text" placeholder='Condition, procedure, doctor...' {...params.inputProps} />
                                        </div>
                                    )}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 8, marginBottom: 8, borderLeft: '1px solid #CFCFCF' }} />
                            <div className='d-flex align-items-center w-50 px-3'>
                                <PinIcon />
                                <Autocomplete
                                    sx={{
                                        display: 'inline-block',
                                        '& input': {
                                            width: '100%',
                                            border: 'none',
                                            outlineWidth: 0
                                        },
                                    }}
                                    id="custom-input-demo2"
                                    options={options}
                                    renderInput={(params) => (
                                        <div className='ms-2' ref={params.InputProps.ref}>
                                            <input type="text" placeholder='Condition, procedure, doctor...' {...params.inputProps} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div onClick={() => searchClicked()} className={`${styles.searchButton} d-flex align-items-center justify-content-center`}>
                            <SearchIcon />
                        </div>
                    </div>
                    :
                    <div className={styles.mobileWrapper}>
                        <div className='d-flex flex-column w-100 p-3'>
                            <div className='d-flex align-items-center border-bottom'>
                                <SearchIcon2 />
                                <Autocomplete
                                    sx={{
                                        display: 'inline-block',
                                        '& input': {
                                            width: '100%',
                                            border: 'none',
                                            outlineWidth: 0,
                                        }
                                    }}
                                    fullWidth={true}
                                    id="custom-input-demo"
                                    options={options}
                                    renderInput={(params) => (
                                        <div className='ms-2' ref={params.InputProps.ref}>
                                            <input type="text" placeholder='Condition, procedure, doctor...' {...params.inputProps} />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='d-flex align-items-center border-bottom mb-3'>
                                <PinIcon />
                                <Autocomplete
                                    sx={{
                                        display: 'inline-block',
                                        '& input': {
                                            width: '100%',
                                            border: 'none',
                                            outlineWidth: 0
                                        },
                                    }}
                                    id="custom-input-demo2"
                                    options={options}
                                    renderInput={(params) => (
                                        <div className='ms-2' ref={params.InputProps.ref}>
                                            <input type="text" placeholder='Condition, procedure, doctor...' {...params.inputProps} />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='w-100'>
                                <button onClick={() => searchClicked()}  className='defaultButton w-100'> <span className='w-100 text-center'>{t('search_care')}</span> </button>
                            </div>
                        </div>
                    </div>

            }
        </>

    )
}

export default SearchBar;