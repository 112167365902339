import React from 'react';

const Contact = () => {
    return(
        <div>
            Contact Atqifja
        </div>
    )
}

export default Contact;