import React from 'react';
import styles from './styles/banner1.module.scss';
import Background1 from '../../assets/images/homeBackground1.png';

const HomeBanner1 = () => {
    return (
        <div className="container-fluid p-0 pt-5">
                <div className='position-relative '>
                    <img src={Background1} className={styles.background1Img} width={'100%'} alt="" />
                    <div className=" position-absolute top-0 w-100">
                        <div className="container">
                            <div className="row px-lg-4 pt-4">
                                <div className="col-sm-12 col-md-6">
                                    <h3 className={styles.homeHeading}>Your entire healthcare with a few clicks</h3>
                                    <ul className='pt-md-4 pt-xs-2'>
                                        <li className='mb-lg-4'>
                                            <div className="d-flex flex-column">
                                                <h4 className={styles.listHeading}>Browse doctors that may help you</h4>
                                                <p className={styles.listText}>Use the filters to find a doctor of the specialty you need in your area, with experience in the subject that concerns you.</p>
                                            </div>
                                        </li>
                                        <li className='mb-lg-4'>
                                            <div className="d-flex flex-column">
                                                <h4 className={styles.listHeading}>View the doctor’s profile and read the reviews</h4>
                                                <p className={styles.listText}>Read reviews from other patients, details about his experience and the cost of the services he offers.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex flex-column">
                                                <h4 className={styles.listHeading}>Book an Appointment for FREE</h4>
                                                <p className={styles.listText}>See the available times, fill in your contact details, the reason for your visit and you're ready!</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-12 col-md-6"></div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
    )
}

export default HomeBanner1;