import React from "react";

function Icon() {
  return (
    <svg
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        stroke="#28303F"
        strokeLinecap="round"
        strokeWidth="1.178"
        d="M15.134 7.282H2.57m12.564 0c.868 0 1.57.703 1.57 1.57v3.141a3.14 3.14 0 01-3.14 3.141H4.14A3.14 3.14 0 011 11.994V8.851c0-.867.703-1.57 1.57-1.57m12.564 0v-1.57a1.57 1.57 0 00-1.57-1.571M2.57 7.281v-1.57c0-.867.704-1.57 1.571-1.57m9.423 0H4.14m9.423 0v-1.57A1.57 1.57 0 0011.994 1H5.71a1.57 1.57 0 00-1.57 1.57v1.571m3.14 5.497h3.142"
      ></path>
    </svg>
  );
}

export default Icon;
